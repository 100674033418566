import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  makeSelectApi
} from 'containers/App/selectors';
import { makeSelectUsers } from 'containers/App/Users/selectors';

import { LOCATION_CHANGE } from 'connected-react-router';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';

import {
  loadDefaultPageDataSuccess,
  loadDefaultPageDataFailure,
  loadIssuerData,
} from './actions';

function* setDefaultPageForUnAuthenticated() {
  const api = yield select(makeSelectApi());
  try {
    const issuer = yield api.get('api/issuer');
    yield put(loadIssuerData(issuer));
    const { id } = issuer;
      let result = null;
      const { host } = window.location;
      const subdomain = host.split('.')[0];
      if (subdomain === "portal") {
        result = yield api.get('api/issues', { params: { text: 'status%Active' } });
      } else {
        result = yield api.get('api/issues', { params: { text: `issuerId%${id}%status%Active` } });
      }
      yield put(loadDefaultPageDataSuccess(result));
    
  } catch (err) {
    console.error('Error fetching issues', err);
    yield put(loadDefaultPageDataFailure());
  }
}

export default function* defaultPageSaga() {
    yield call(setDefaultPageForUnAuthenticated);
    yield takeLatest(
      [LOCATION_CHANGE, CHANGE_LOCALE],
      setDefaultPageForUnAuthenticated
    );
}
