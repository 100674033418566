/* eslint-disable react/prop-types */
// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
import App from 'containers/App';
import Dashboard from 'containers/Dashboard';
import NotFoundPage from 'containers/NotFoundPage';
import MarketplacePage from 'containers/MarketplacePage';
import AuthPage from 'containers/AuthPage'; // TODO: French content is pending
import AccountPage from 'containers/AccountPage';
import TradeView from './containers/TradeView';
import Portfolio from './containers/Portfolio';
import DefaultPage from './containers/DefaultPage';

export const subdomainRoutes = {
  abcd: [
    {
      ...DefaultPage,
      path: '/:slug/home',
      exact: true,
    },
    {
      ...Dashboard,
      path: '/:slug/dashboard',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/profile', // protected under AuthProvider
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/login',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/forgot',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/resetpassword',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/register',
      exact: true,
    },
    {
      ...TradeView,
      path: '/:slug/issue/:symbol',
      exact: true,
    },
    {
      ...Portfolio,
      path: '/:slug/portfolio',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/security', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/documents', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/activity', // protected under AuthProvider
      exact: true,
    },
    {
      ...NotFoundPage,
      path: '*',
      status: 404,
      page: false,
    },
  ],
  wxyz: [
    {
      ...Dashboard,
      path: '/:slug/dashboard',
      exact: true,
    },
    {
      ...DefaultPage,
      path: '/:slug/home',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/profile', // protected under AuthProvider
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/login',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/forgot',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/resetpassword',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/register',
      exact: true,
    },
    {
      ...TradeView,
      path: '/:slug/issue/:symbol',
      exact: true,
    },
    {
      ...Portfolio,
      path: '/:slug/portfolio',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/security', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/documents', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/activity', // protected under AuthProvider
      exact: true,
    },
    {
      ...NotFoundPage,
      path: '*',
      status: 404,
      page: false,
    },
  ],
  altrust: [
    {
      ...Dashboard,
      path: '/:slug/dashboard',
      exact: true,
    },
    {
      ...DefaultPage,
      path: '/:slug/home',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/profile', // protected under AuthProvider
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/login',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/forgot',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/resetpassword',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/register',
      exact: true,
    },
    {
      ...TradeView,
      path: '/:slug/issue/:symbol',
      exact: true,
    },
    {
      ...Portfolio,
      path: '/:slug/portfolio',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/security', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/documents', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/activity', // protected under AuthProvider
      exact: true,
    },
    {
      ...NotFoundPage,
      path: '*',
      status: 404,
      page: false,
    },
  ],
  heartwood: [
    {
      ...Dashboard,
      path: '/:slug/dashboard',
      exact: true,
    },
    {
      ...DefaultPage,
      path: '/:slug/home',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/profile', // protected under AuthProvider
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/login',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/forgot',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/resetpassword',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/register',
      exact: true,
    },
    {
      ...TradeView,
      path: '/:slug/issue/:symbol',
      exact: true,
    },
    {
      ...Portfolio,
      path: '/:slug/portfolio',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/security', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/documents', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/activity', // protected under AuthProvider
      exact: true,
    },
    {
      ...NotFoundPage,
      path: '*',
      status: 404,
      page: false,
    },
  ],
  dalfen: [
    {
      ...Dashboard,
      path: '/:slug/dashboard',
      exact: true,
    },
    {
      ...DefaultPage,
      path: '/:slug/home',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/profile', // protected under AuthProvider
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/login',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/forgot',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/resetpassword',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/register',
      exact: true,
    },
    {
      ...TradeView,
      path: '/:slug/issue/:symbol',
      exact: true,
    },
    {
      ...Portfolio,
      path: '/:slug/portfolio',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/security', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/documents', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/activity', // protected under AuthProvider
      exact: true,
    },
    {
      ...NotFoundPage,
      path: '*',
      status: 404,
      page: false,
    },
  ],
  portal: [
    {
      ...DefaultPage,
      path: '/:slug/home',
      exact: true,
    },
    {
      ...Dashboard,
      path: '/:slug/dashboard',
      exact: true,
    },
    {
      ...TradeView,
      path: '/:slug/issue/:symbol',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/login',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/forgot',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/resetpassword',
      exact: true,
    },
    {
      ...AuthPage,
      path: '/:slug/register',
      exact: true,
    },
    {
      ...Portfolio,
      path: '/:slug/portfolio',
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/security', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/documents', // protected under AuthProvider
      exact: true,
    },
    {
      ...AccountPage,
      path: '/:slug/account/profile', // protected under AuthProvider
      exact: true,
    },
    {
      ...NotFoundPage,
      path: '*',
      status: 404,
      page: false,
    }
  ],
};

// Follow the pattern of /en/pagename
export default [
  {
    ...App,
    routes: [
      {
        ...NotFoundPage,
        path: '*',
        status: 404,
        page: false,
      },
    ],
    subDomainRoutes: {
      abcd: [
        {
          ...App,
          routes: [...subdomainRoutes.abcd],
        },
      ],
      altrust: [
        {
          ...App,
          routes: [...subdomainRoutes.altrust],
        },
      ],
      dalfen: [
        {
          ...App,
          routes: [...subdomainRoutes.dalfen],
        },
      ],
      heartwood: [
        {
          ...App,
          routes: [...subdomainRoutes.heartwood],
        },
      ],
      wxyz: [
        {
          ...App,
          routes: [...subdomainRoutes.wxyz],
        },
      ],
      portal: [
        {
          ...App,
          routes: [...subdomainRoutes.portal],
        },
      ],
    },
  },
];
